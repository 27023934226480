import React, { useContext, useState } from 'react';
import { AuthContext } from './AuthContext'; // Import AuthContext
import { useNavigate } from 'react-router-dom'; // Import useNavigate

function Login() {
  const { setAuthenticated, setUserId, setUserEmail, setToken } = useContext(AuthContext);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
 
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/login`, {
        method: 'POST',
        credentials: 'include', // Include cookies with request
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      console.log("response from server received by Login.js component?", response.ok);
      if (response.ok) {
        // think about swapping order or sethAuthenticated() and const data = await()
        const data = await response.json();
        setAuthenticated(true);
        setUserId(data.userId);
        setUserEmail(data.userEmail);
        localStorage.setItem('login data.userEmail', data.userEmail);
        localStorage.setItem('login data.userId', data.userId);
        console.log("creds set in login", data.userId, data.userEmail)
        navigate('/'); // navigate back to landing which will forward us to MainPage
      } else {
        setAuthenticated(false);
        setUserId(null);
        setUserEmail(null);
        setToken(null);
        // Error handling for non-OK responses
        const errorData = await response.json();
        setErrorMessage(errorData.message || 'Login failed. Please try again.');
      }
    } catch (error) {
      console.error('Error during login:', error);
      setErrorMessage('An error occurred during login. Please try again.');
    }
  };

  return (
    <div>
      <form onSubmit={handleSubmit}>
        <input 
          type="email" // Change type to email for better validation & communication
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          placeholder="Email"
        />
        <input 
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          placeholder="Password"
        />
        <button type="submit">Login</button>
        {errorMessage && <p>{errorMessage}</p>} {/* Display error messages */}
      </form>
    </div>
  );
}

export default Login;

