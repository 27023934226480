// Chat.js
import React, { useState, useEffect, useRef } from 'react';
import './Chat.css';

function Chat({ onClose, token, chatEmail }) {

  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const [ws, setWs] = useState(null);
  const messagesEndRef = useRef(null);

    // Store the token in state (from initialToken or sessionStorage)
    const [initialToken, setToken] = useState(() => {
        // Get the token from session storage if available
        console.log("initialToken", token);
        return sessionStorage.getItem('token') || token;
      });

    const scrollToBottom = () => {
        if (messagesEndRef.current) {
          messagesEndRef.current.scrollIntoView({ behavior: 'smooth' });
        }
      };

      // Scroll to the bottom whenever messages are updated
  useEffect(() => {
    scrollToBottom();
  }, [messages]);

  

  useEffect(() => {

    if (token) {
        sessionStorage.setItem('token', token);
      }

      
    if (!token) {
      console.error('No token found');
      return;
    }

    // Establish WebSocket connection
    const socket = new WebSocket(`wss://pvpmvp.com:3002/?token=${token}`);
    setWs(socket);

    socket.onopen = () => {
      console.log('Connected to chat server');
    };

    socket.onmessage = (event) => {
      const message = JSON.parse(event.data);

    //   console.log('received message from chat server', message);

      if (message.type === 'chatHistory') {
        // Receive chat history
        setMessages(message.data);
      } else if (message.type === 'newMessage') {
        // Receive a new message
        setMessages((prevMessages) => [...prevMessages, message.data]);
      }
    };

    socket.onclose = () => {
      console.log('Disconnected from chat server');
    };

    socket.onerror = (error) => {
      console.error('Chat WebSocket error:', error);
    };

    return () => {
      socket.close();
    };
  }, [token]);

  

  const sendMessage = () => {
    if (ws && input.trim() !== '') {
      const message = {
        type: 'newMessage',
        content: input.trim(),
      };
      ws.send(JSON.stringify(message));
      setInput('');
    }
  };
  return (
    <div className="chat-modal">
      <div className="chat-header">
        <button 
            onClick={() => console.log("activate")}
            style={{   
                color: '#00ff15',
                transition: 'background-color 0.3s ease', // Smooth color transition
                }}
            >
            Online
        </button>
        <h2>Global Chat</h2>
        <button onClick={onClose}> Close</button>
      </div>
      <div className="chat-messages">
        {messages.map((msg, index) => {
          const isMe = msg.playerId === chatEmail; // Assuming chatEmail is the logged-in user's email
          return (
            <div key={index} className={`chat-message ${isMe ? 'from-me' : 'from-other'}`}>
              <span className="chat-timestamp">{new Date(msg.timestamp).toLocaleTimeString()}</span>
              <span className="chat-playerId">{msg.playerId}</span>
              <p className="chat-content">{msg.content}</p>
            </div>
          );
        })}
        <div ref={messagesEndRef} />
      </div>
      <div className="chat-input">
        <input
          type="text"
          value={input}
          placeholder="Type a message..."
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => {
            if (e.key === 'Enter') sendMessage();
          }}
        />
        <button onClick={sendMessage}>Send</button>
      </div>
    </div>
  );
  
  
  
  
}

export default Chat;
