import React, { useContext, useEffect, useState, useRef } from 'react';
import { useParams, useLocation, useNavigate } from 'react-router-dom';
import queryString from 'query-string'; // Helps parse query parameters
import { AuthContext } from './AuthContext';
import './GamePage.css'; // Import the CSS file
import Chat from './Chat'; // Import the Chat component

function GamePage() {
  const { gameId } = useParams(); // Capture gameId from the URL
  const location = useLocation(); 
  const navigate = useNavigate(); // For navigation handling
  const [gameState, setGameState] = useState({ players: {}, objects: {} });
  const [ws, setWs] = useState(null); // WebSocket connection state
  const [isConnected, setIsConnected] = useState(false); // Track connection status
  const { isAuthenticated, setAuthenticated, setUserId, setUserEmail, userEmail, token, setToken } = useContext(AuthContext);
  const gameBoardRef = useRef(null); // Create a ref for the game board
  const [scaleFactor, setScaleFactor] = useState(6); // * = ZOOM IN, / = ZOOM OUT
  const [isChatOpen, setIsChatOpen] = useState(false); // Chat visibility state

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen); // Toggle chat open/close state
  };


  useEffect(() => {
      // Parse the token from the query parameters
      const { token } = queryString.parse(location.search);
      
      // const socket = new WebSocket(`wss://18.222.242.133:3001/?token=${token}&gameId=${gameId}`);
      const socket = new WebSocket(`wss://pvpmvp.com:3001/?token=${token}&gameId=${gameId}`);
      
      socket.onopen = () => {
        console.log('WebSocket connection established');
        setWs(socket);
        setIsConnected(true); // Set connection status to true
      };

      socket.onmessage = (event) => {
        try {
          const gameStateUpdates = JSON.parse(event.data); // Parse the incoming message
      
          if (gameStateUpdates.fullState) {
            // Handle full state
            setGameState(gameStateUpdates.fullState);

            const gameBoard = gameBoardRef.current;

            gameBoard.style.transform = `scale(${scaleFactor})`;

          }

          if (gameStateUpdates.changes) {
            setGameState((prevState) => {
              const { players: prevPlayers, objects: prevObjects } = prevState;
      
              // Update players: handle null values for removal
              const updatedPlayers = { ...prevPlayers };
              Object.entries(gameStateUpdates.changes.players || {}).forEach(([playerId, playerData]) => {
                if (playerData === null) {
                  delete updatedPlayers[playerId]; // Remove player if null
                } else {
                  updatedPlayers[playerId] = playerData; // Update or add player
                }
              });
      
              // Update objects: handle null values for removal
              const updatedObjects = { ...prevObjects };
              Object.entries(gameStateUpdates.changes.objects || {}).forEach(([objectId, objectData]) => {
                if (objectData === null) {
                  delete updatedObjects[objectId]; // Remove object if null
                } else {
                  updatedObjects[objectId] = objectData; // Update or add object
                }
              });
      
              // Return the updated game state
              return {
                ...prevState,
                players: updatedPlayers,
                objects: updatedObjects,
              };
            });
          }
        } catch (error) {
          console.error('Error parsing WebSocket message:', error);
        }
      };

      socket.onerror = (error) => {
        console.error('WebSocket error:', error);
      };

      socket.onclose = (event) => {
        console.log(`WebSocket connection closed with code: ${event.code}, reason: ${event.reason}`);
        setWs(null);
        setIsConnected(false); // Reset connection status on close

        if (event.code === 4000) { // Server reset code
          // logout the user here as well
          setAuthenticated(false);
          setUserId(null);
          setUserEmail(null);
          localStorage.removeItem('userId');
          localStorage.removeItem('userEmail');
          setToken(null);
          navigate('/'); // Redirect to login page
        } else if (event.code === 4001 && event.reason === 'Inactive') {
          navigate('/main'); // Redirect back to MainPage
        } else if (event.code === 1005) { //back button pressed (DODGE)
          navigate('/main'); // Redirect back to MainPage (for now...)
        } else if (event.code === 1006) { //gameServer reset
          navigate('/main'); // Redirect back to MainPage (for now...)
        } else if (event.code === 4187) { //got got
          navigate('/main'); // Redirect back to MainPage (for now...)
        } else {
          setAuthenticated(false);
          setToken(null)
          setUserId(null);
          setUserEmail(null);
          localStorage.removeItem('userId');
          localStorage.removeItem('userEmail');
          navigate('/'); // Redirect back to login for any socket closure
        }
      };

        // Handle cleanup on component unmount or navigation away
        const handleBeforeUnload = () => {
          if (socket) {
            socket.close(); // Close WebSocket connection on navigation away
          }
        };
      
        window.addEventListener('beforeunload', handleBeforeUnload);
      
        // Cleanup WebSocket and event listeners on unmount
        return () => {
          if (socket) {
            socket.close(); // Ensure WebSocket is closed on unmount
          }
          window.removeEventListener('beforeunload', handleBeforeUnload);
        };
  }, [location.search, gameId, navigate]);
  
  useEffect(() => {
    const gameBoard = gameBoardRef.current;
  
    if (gameBoard) {
      // Add event listeners for mobile/tablets
      gameBoard.addEventListener('touchstart', handleInteraction);
      gameBoard.addEventListener('touchmove', handleInteraction);
      gameBoard.addEventListener('touchend', handleInteraction);
      // Add event listeners for desktop/trackpad
      gameBoard.addEventListener('mousedown', handleInteraction);
      gameBoard.addEventListener('mousemove', handleInteraction);
      gameBoard.addEventListener('mouseup', handleInteraction);
    }
  
    return () => {
      if (gameBoard) {
        // Remove event listeners
        gameBoard.removeEventListener('touchstart', handleInteraction);
        gameBoard.removeEventListener('touchmove', handleInteraction);
        gameBoard.removeEventListener('touchend', handleInteraction);
        gameBoard.removeEventListener('mousedown', handleInteraction);
        gameBoard.removeEventListener('mousemove', handleInteraction);
        gameBoard.removeEventListener('mouseup', handleInteraction);
      }
    };
  }, [ws, isConnected, gameBoardRef]);

 // UseEffect to monitor gameState and render changes
  useEffect(() => {
    clearBoard(); // Clear the board before re-rendering

    Object.keys(gameState.objects).forEach((objectId) => {
      
      const currentObject = gameState.objects[objectId];

      if (currentObject.objectType == 'player') {
        renderPlayerEntity(currentObject)
      } else if (currentObject.objectType == 'projectile') {
        renderObjectEntity(currentObject); // Only render if the object exists
      } else {
        console.log(`Skip rendering, object not found. ${objectId}`);
      }
    });

  }, [gameState]); // Trigger whenever gameState updates
  
  function renderObjectEntity(objectEntity) {
    // Check if the objectEntity is valid before proceeding
    if (!objectEntity) {
      console.error("Attempted to render a null or undefined objectEntity.");
      return; // Exit early if objectEntity is null or undefined
    }
  
    const { id, objectType, x, y, shape, powerups, faction, hp } = objectEntity; // Use object properties for rendering
    
    // console.log("Rendering objectEntity:", objectEntity);
  
    // Round the x and y coordinates to the nearest whole number
    const roundedX = Math.round(x);
    const roundedY = Math.round(y);
  
    // Check if shape is valid before iterating
    if (!shape || !Array.isArray(shape)) {
      console.error(`Invalid or missing shape for objectEntity with id: ${id}`, shape);
      return; // Early return if shape is missing or invalid
    }
  
    // Iterate over the shape, which is an array of relative positions
    shape.forEach(([dx, dy], index) => {
      const pixelX = roundedX + dx; // origin X plus shape x
      const pixelY = roundedY + dy; // origin Y plus shape y
  
      if (pixelX >= 0 && pixelX < 1024 && pixelY >= 0 && pixelY < 1024) {
        // Create a div element to represent this pixel
        const pixelDiv = document.createElement('div');
  
        // Apply the base class for object pixels and specific styling based on collisionType
        if (objectType === 'projectile') {
          pixelDiv.classList.add('projectile'); // Class for projectiles
        } else {
          pixelDiv.classList.add('objectEntity-pixel'); // Generic object class for non-projectiles
        }
  
        // Set the position of the pixel
        pixelDiv.style.left = `${pixelX}px`;
        pixelDiv.style.top = `${pixelY}px`;
  
        // Append the pixel to the game board using the gameBoardRef
        if (gameBoardRef && gameBoardRef.current) {
          gameBoardRef.current.appendChild(pixelDiv);
        }
      }
    });
  }
  
  function renderPlayerEntity(playerEntity) {
    const { id, objectType, x, y, shape, powerups, faction, hp } = playerEntity; // Assume 'id' contains the player's ID
    
    // Check if shape is valid before iterating
    if (!shape || !Array.isArray(shape)) {
      console.error(`Invalid or missing shape for playerEntity with id: ${id}`, shape);
      return; // Early return if shape is missing or invalid
    }

    // console.log("rendering playerEntity:", faction, hp, x, y, powerups, shape, id);

    // Create a div for displaying the player's ID
    const playerIdDiv = document.createElement('div');
    playerIdDiv.classList.add('player-id');
    playerIdDiv.innerHTML = `${id}`;
    playerIdDiv.style.left = `${x}px`; // Position at player's x position
    playerIdDiv.style.top = `${y - 9}px`; // Position above the player (adjust as needed)
    playerIdDiv.style.zIndex = '10'; // Keep it above the player pixels
  
    // Create a div for displaying the player's HP
    const playerHPDiv = document.createElement('div');
    playerHPDiv.classList.add('player-hp');
    playerHPDiv.style.left = `${x}px`; // Position at player's x position
    playerHPDiv.style.top = `${y - 5}px`; // Position above the ID
    playerHPDiv.style.fontWeight = 'bold'; // Make the HP text bold
    playerHPDiv.innerHTML = `hp:${hp}`;

// Change color and effect based on HP value
if (hp > 100) {
  playerHPDiv.style.color = 'blue'; // Use bright blue
  playerHPDiv.style.textShadow = '0 0 10px cyan, 0 0 20px cyan, 0 0 30px cyan'; // Add stronger sparkle effect
} else if (hp === 100) {
  playerHPDiv.style.color = 'darkblue'; // Deep blue
} else if (hp >= 75) {
  playerHPDiv.style.color = 'green'; // More than 75% HP
} else if (hp >= 50) {
  playerHPDiv.style.color = 'orange'; // More than 50% HP
} else if (hp >= 25) {
  playerHPDiv.style.color = 'red'; // More than 25% HP
} else {
  playerHPDiv.style.color = 'darkgray'; // 0% or less HP
}




    // Append the player's ID div to the game board
    if (gameBoardRef && gameBoardRef.current) {
      gameBoardRef.current.appendChild(playerIdDiv);
      gameBoardRef.current.appendChild(playerHPDiv);
    }
  
    // Iterate over the shape, which is an array of relative positions
    shape.forEach(([dx, dy], index) => {
      const pixelX = x + dx; // origin X plus shape x
      const pixelY = y + dy; // origin Y plus shape y
  
      if (pixelX >= 0 && pixelX < 1024 && pixelY >= 0 && pixelY < 1024) {

        // Create a div element to represent this pixel
        const pixelDiv = document.createElement('div');
        pixelDiv.classList.add('playerEntity-pixel'); // Apply the base class for all pixels
    
        // Apply specific classes based on the faction
        switch (faction) {
          case 'Blue':
            pixelDiv.classList.add('factionBlue');
            break;
          case 'Red':
            pixelDiv.classList.add('factionRed');
            break;
          default:
            pixelDiv.style.backgroundColor = 'yellow'; // Default color for unknown types
        }
    
        // Set the position of the pixel
        pixelDiv.style.left = `${pixelX}px`; 
        pixelDiv.style.top = `${pixelY}px`;
    
        // Append the pixel to the game board using the gameBoardRef
        if (gameBoardRef && gameBoardRef.current) {
          gameBoardRef.current.appendChild(pixelDiv);
        }
      }
    });
  }
  
  
  // Function to clear the game board
  function clearBoard() {
    if (gameBoardRef && gameBoardRef.current) {
      while (gameBoardRef.current.firstChild) {
        gameBoardRef.current.removeChild(gameBoardRef.current.firstChild);
      }
    }
  }

function sendToServer(data) {
  if (ws.readyState === WebSocket.OPEN) {
    ws.send(JSON.stringify(data));
  }
}
let clickStartCoords = null;
let lastTapTime = 0;
const doubleTapDelay = 300;  // Milliseconds
let tapTimeout = null;  // For handling the difference between single tap and double tap

function handleInteraction(event) {
  // Ensure the WebSocket is connected
  if (ws && isConnected && isAuthenticated) {
    const rect = gameBoardRef.current.getBoundingClientRect();

    // Initialize eventData and handle scaling
    let eventData = {
      x: 0,
      y: 0,
      timestamp: new Date().toISOString(),
      type: event.type,
    };

    const adjustCoordinates = (clientX, clientY) => {
      return {
        x: Math.round((clientX - rect.left) / scaleFactor),  // Rounding x coordinate
        y: Math.round((clientY - rect.top) / scaleFactor),   // Rounding y coordinate
      };
    };

    // Check if it's a touch event or mouse event and normalize the interaction data

    const isTouchEvent = event.type.startsWith('touch');
    const isMouseEvent = event.type.startsWith('mouse');

    let clientX = 0;
    let clientY = 0;

    if (isTouchEvent) {
      clientX = isTouchEvent ? event.touches[0].clientX : event.clientX;
      clientY = isTouchEvent ? event.touches[0].clientY : event.clientY;
    } else if (isMouseEvent) {
      clientX = event.clientX;
      clientY = event.clientY;
    } else {
      return;
    }

    // Check for both touch and mouse events
    if (isTouchEvent || isMouseEvent) {
      

      switch (event.type) {
        case 'mousedown':
        case 'touchstart':
          const currentTapTime = new Date().getTime();

          // Clear any previous tap timeout to avoid firing a single tap event too early
          if (tapTimeout) clearTimeout(tapTimeout);

          if (currentTapTime - lastTapTime < doubleTapDelay) {
            // Detected double tap - move player
            const moveCoords = adjustCoordinates(clientX, clientY);
            eventData.x = moveCoords.x;
            eventData.y = moveCoords.y;
            eventData.type = 'move';
            ws.send(JSON.stringify(eventData));  // Send move event to server

            lastTapTime = 0;  // Reset the last tap time
            clickStartCoords = null; // Reset clickStartCoords after a move
          } else {
            // This is the start of either aiming or a single tap
            clickStartCoords = adjustCoordinates(clientX, clientY);

            // Set a timeout to determine if it's a single tap or drag-to-aim
            tapTimeout = setTimeout(() => {
              lastTapTime = 0;  // Reset tap time since we determined this is not a double tap
            }, doubleTapDelay);  // Wait for the double-tap delay to confirm it's not a double tap
          }

          lastTapTime = currentTapTime;
          break;

        case 'mousemove':
        case 'touchmove':
          if (clickStartCoords) {
            const moveCoords = adjustCoordinates(clientX, clientY);
            const direction = {
              x: moveCoords.x - clickStartCoords.x,
              y: moveCoords.y - clickStartCoords.y,
            };

            eventData.x = moveCoords.x;
            eventData.y = moveCoords.y;
            eventData.type = 'aim';
            eventData.direction = direction;
            // console.log("Aiming in direction", direction);
          }
          break;

        case 'mouseup':
        case 'touchend':
          // Fire the projectile if the user has been dragging to aim
          if (clickStartCoords) {
            const endCoords = adjustCoordinates(clientX, clientY);
            const direction = {
              x: endCoords.x - clickStartCoords.x,
              y: endCoords.y - clickStartCoords.y,
            };
            const power = Math.sqrt(direction.x * direction.x + direction.y * direction.y);

            if (power > 0) {
              eventData.x = endCoords.x;
              eventData.y = endCoords.y;
              eventData.type = 'shoot';
              eventData.direction = direction;
              eventData.power = power;
              // console.log("Shot fired with direction", direction, "and power", power);

              ws.send(JSON.stringify(eventData));  // Send shooting event to server
            }
            // Reset clickStartCoords to allow new aiming after shooting
            clickStartCoords = null;
          }

          // Clear the tap timeout as it's no longer needed
          if (tapTimeout) clearTimeout(tapTimeout);
          break;

        default:
          console.log("Unhandled event type:", event.type);
          return;  // Exit if the event type is not handled
      }
    }
  }
}

// Ensure that gameBoardRef is not null and all data is valid before rendering
return (
  <div className="game-page">
    <h1>Game Instance: {gameId}</h1>
    {isConnected ? (
      <div>
        <p>Connection Established</p>
        <div
          className="game-board"
          id="game-board"
          ref={gameBoardRef} // Attach the ref to the game board
          style={{ width: '1024px', height: '1024px' }}
        >
          {/* Other game-related content can be rendered here */}
        </div>

        {/* Chat Toggle Button */}
        <button className="chat-toggle-button" onClick={toggleChat}>
          {isChatOpen ? 'Close Chat' : 'Open Chat'}
        </button>

        {/* Conditionally render the Chat component */}
        {isChatOpen && token && <Chat onClose={toggleChat} token={token} chatEmail={userEmail}  />}
      </div>
    ) : (
      <p>Connecting to the game server...</p>
    )}
  </div>
);
}

export default GamePage;
