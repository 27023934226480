import React, { useContext, useState, useEffect } from 'react';
import { Navigate, useNavigate } from 'react-router-dom';
import { AuthContext } from './AuthContext';
import Logout from './Logout';
import './MainPage.css';
import { parseISO } from 'date-fns';
import Chat from './Chat'; // Import the Chat component

function MainPage() {

  const { setAuthenticated, isAuthenticated, isAuthenticating, setAuthenticating, userId, setUserId, userEmail, setUserEmail, token, setToken } = useContext(AuthContext); // Access userId
  const [instanceList, setInstanceList] = useState([]); // Add a state variable for instances
  const [currentDate, setCurrentDate] = useState('');
  const [serverMessage, setServerMessage] = useState('Server Down for Maintenance'); // move to serverside eventually.
  const [loadingInstances, setLoadingInstances] = useState(true);
  const navigate = useNavigate();
  const [isChatOpen, setIsChatOpen] = useState(false);

  // const openChat = () => setIsChatOpen(true);
  // const closeChat = () => setIsChatOpen(false);

  const toggleChat = () => {
    setIsChatOpen(!isChatOpen); // Toggle chat open/close state
  };

  async function checkAuthentication() {
    setAuthenticating(true);
    try {
      console.log("checking auth in MainPage?", isAuthenticated);
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/checkAuth`, {
        method: 'GET',
        credentials: 'include', // Include cookies with request
        headers: {
          'Content-Type': 'application/json',
        }
      });
  
      const data = await response.json();

      console.log("auth data!", response.ok, data);
  
      if (response.ok && data.status === 'success') {

        setUserId(data.userId);
        setUserEmail(data.userEmail);
        setAuthenticated(true);
  
        let currentToken = token;
        if (!currentToken) {
          const tokenData = await fetchToken();
          currentToken = tokenData.token;
          setToken(currentToken);
        }
        // console.log("currentToken in MainPage", currentToken); // false
        // Use currentToken if needed immediately
      } else {
        setAuthenticated(false);
        setUserId(null);
        setUserEmail(null);
        setToken(null);
        navigate('/'); // user is not authenticated, redirect to login
      }
    } catch (error) {
      console.error('Error checking authentication', error);
      setAuthenticated(false);
      setUserId(null);
      setUserEmail(null);
      setToken(null);
      navigate('/');
    }
    setAuthenticating(false);
  }

  // Fetch and update the current date
  const fetchCurrentDate = () => {
    fetch(`${process.env.REACT_APP_API_URL}/api`)
      .then((response) => response.json())
      .then((data) => {
        setCurrentDate(data.currentDate);
        setServerMessage(data.message);
      })
      .catch((error) => {
        console.error('Error fetching data:', error);
      });
  };

  const fetchInstanceList = async () => {
    setLoadingInstances(true); // Set loading to true when fetch starts
    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/api/instance`, {
        credentials: 'include', // Include cookies with request
      });
      
      if (!response.ok) {
        // throw new Error(`HTTP error! Status: ${response.status}`);
        if (response.status === 503) {
          // If the game server is down, show a message to the user
          setServerMessage('Game server is unavailable. Please try again later.');
          setInstanceList([]); // Clear any instance list
          setLoadingInstances(false); // Ensure loading is set to false
        } else {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
      }
      
      const jsonResponse = await response.json(); // Parse the JSON response
  
      const { responseData } = jsonResponse; // Unwrap the responseData key
  
      // Assuming responseData is the array of instances
      setInstanceList(responseData.currentInstances); // Set the instanceList state with the instances array
      setLoadingInstances(false); // Set loading to false when fetch is complete
    } catch (error) {
      console.error('Error fetching instance list:', error);
      // setInstanceList(null); // Set the instanceList to null for security?
      setLoadingInstances(false); // Ensure loading is false if an error occurs
    }
  };

  const handleInstanceClick = async (instance) => {
    try {
      let currentToken = token;
  
      if (!currentToken) {
        console.log("userEmail in handleInstanceClick", userEmail);
        const tokenData = await fetchToken();
        currentToken = tokenData.token;
        setToken(currentToken);
      }
  
      navigate(`/game/${instance.id}?token=${currentToken}`);
    } catch (error) {
      console.error('Error fetching token:', error);
    }
  };

  // Fetch token and UUID from the token server
  async function fetchToken() {
    // Check if there's already a stored playerId in localStorage
    // let storedPlayerId = localStorage.getItem('playerId');

    console.log("userEmail for fetch token", userEmail);

    const response = await fetch(`https://pvpmvp.com:5001/token`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json'
      },
      credentials: 'include', // Include cookies with request
      body: JSON.stringify({ userEmail })
    });

    if (!response.ok) {
      throw new Error('Failed to fetch token');
    }

    const data = await response.json();
    return data; // { token, playerEmail }
  }


  function formatDateToString(date) {
    return `${date.getFullYear()}-${(date.getMonth() + 1).toString().padStart(2, '0')}-${date.getDate().toString().padStart(2, '0')}`;
  }

  useEffect(() => {
    fetchCurrentDate();
    // Fetch date and time from server every second
    const dateIntervalId = setInterval(fetchCurrentDate, 1000);
    return () => {
      clearInterval(dateIntervalId);
    };
  }, []);
  


    // Initialize authentication and instance list
  useEffect(() => {
    async function initialize() {
      try {
        await checkAuthentication();

      // console.log("useEffect checkAuth fin");
      console.log("you gettin in?", isAuthenticated, userId, userEmail);

        if (isAuthenticated && userId) {
          await fetchInstanceList();
        }
      } catch (error) {
        console.error('Error in initialization:', error);
      }
    }
    initialize();
  }, [isAuthenticated, userId]);

  useEffect(() => {
    if (userId) {
      localStorage.setItem('userId', userId);
    } else {
      localStorage.removeItem('userId');
    }
  }, [userId]);
  
  useEffect(() => {
    if (userEmail) {
      localStorage.setItem('userEmail', userEmail);
    } else {
      localStorage.removeItem('userEmail');
    }
  }, [userEmail]);

  
  useEffect(() => {
    if (userId && userEmail) {
      setAuthenticated(true);
    } else {
      setAuthenticated(false);
      setToken(null);
    }
  }, [userId, userEmail]);


  // Display loading screen while checking auth status
  if (isAuthenticating || loadingInstances) {
    return <div>Loading...</div>; // Show loading until authentication and instanceList are ready
  }

  if (!isAuthenticated) {
    console.log("you gettin kicked out?", isAuthenticated, userId, userEmail);
    // Redirect to the landing page if not authenticated
    return <Navigate to="/" />;
  } else { }

  function formatDate(dateString) { /// 3/3 FORMAT DATE functions
    const parts = dateString.split('T')[0].split('-'); // Split by 'T' and then by '-'
    const year = parts[0];
    const month = parts[1];
    const day = parts[2];
    return `${month}/${day}/${year}`;
  }

  // make each mapped instance a button that when clicked, generates a token and routes the user to that game server.
  // make game servers so they auto start once a threshold of players joins.  ensure only on public key may join at a time.
  // figure out how to start/restart game servers based on enough players being "ready" 
  return (
    <div className="App">
      <h2>Auth Check App</h2>
      <div className="header-info">
        <Logout />
        <p>Current Server Date and Time: {currentDate}</p>
        <p>Message from the Admin: {serverMessage}</p>
      </div>
      
      <button className="chat-toggle-button" onClick={toggleChat}>
          {isChatOpen ? 'Close Chat' : 'Open Chat'}
        </button>

      {isChatOpen && token && <Chat onClose={toggleChat} token={token} chatEmail={userEmail}  />}

      <h2>Current Instances</h2>

      {loadingInstances ? (
        <p>Loading instances...</p>
      ) : (
        <ul>
          {instanceList.map((instance) => (
            <li key={instance.id}>
              <button onClick={() => handleInstanceClick(instance)}>
                <strong>ID: </strong>{instance.id} <strong>Status: </strong>{instance.status} <strong>Players: </strong> {instance.players}
              </button>
            </li>
          ))}
        </ul>
      )}
    </div>
  );
}

export default MainPage;