import React, { createContext, useState } from 'react';

export const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  // const [isAuthenticated, setAuthenticated] = useState(false);
  const [isAuthenticating, setAuthenticating] = useState(true);
  // const [userId, setUserId] = useState(null);
  // const [userEmail, setUserEmail] = useState(null);
  const [token, setToken] = useState(null); // Add token state

  const [userEmail, setUserEmail] = useState(localStorage.getItem('userEmail') || null);
const [userId, setUserId] = useState(localStorage.getItem('userId') || null);
const [isAuthenticated, setAuthenticated] = useState(() => {
  return localStorage.getItem('userId') && localStorage.getItem('userEmail') ? true : false;
});

  // Context provider values
  const value = {
    isAuthenticated,
    setAuthenticated,
    isAuthenticating,
    setAuthenticating,
    userId,
    setUserId,
    userEmail,
    setUserEmail,
    token,
    setToken
  };

  return (
    <AuthContext.Provider value={value}>
      {children}
    </AuthContext.Provider>
  );
};
